interface PathTranslations {
	[key: string]: Translation;
}

interface Translation {
	[key: string]: string;
}

const pathTranslations: PathTranslations = {
	teasing: {
		fr: 'bientot',
		en: 'teasing'
	},
	business: {
		fr: 'entreprises',
		en: 'employers'
	},
	about: {
		fr: 'a-propos',
		en: 'about'
	}
};

export default pathTranslations;
