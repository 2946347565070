export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0,4,5];

export const dictionary = {
		"/api/login": [18],
		"/api/profile": [19],
		"/[lang]": [6,[2]],
		"/[lang]/(private)/_/questionnaire/[id]": [~7,[2,3]],
		"/[lang]/(private)/booking/edit/[type]/[booking_id]": [8,[2,3]],
		"/[lang]/(private)/followup": [9,[2,3]],
		"/[lang]/(public)/login": [~14,[2]],
		"/[lang]/(public)/privacy": [~15,[2]],
		"/[lang]/(private)/questionnaire": [~10,[2,3,4]],
		"/[lang]/(private)/questionnaire/[questionnaire_id]/[category]/[question]": [11,[2,3,4,5]],
		"/[lang]/(private)/report/[id]": [~12,[2,3]],
		"/[lang]/(public)/signout": [16,[2]],
		"/[lang]/(private)/summary/[id]": [~13,[2,3]],
		"/[lang]/(public)/terms": [~17,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';